<template>
  <div id="defaultId1" class="company_detail">
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false">
      <div>
     
        <h2 id="gf7b16" class="operations-head detail_title_20 margin-bottom20">
          <div>
       
            <span class="operations-headspan" v-if="activeIEports=='2'">
              Top 10 Exporters
            </span>
            <span class="operations-headspan" v-if="activeIEports=='1'">
              Top 10 Importers
             
            </span>
            <el-select style="width:  14.2857143rem;margin-left: 40px;"  v-model="Importyear" placeholder="Please Select" @change="ImportYear">
            <el-option v-for="item in Yearoptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          </div>

          <div class="flex_between_box">
            <div id="ge4e89" class="u41151">

              <div id="g8b81a" @click="CheckClick('table1')" :class=" activeIcon == 'table1' ?'ub7903 u72482' :'ub7903'">
                <svg id="idex5xpV" class="icon" t="1718690711396" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4563" width="2.57rem" height="2.57rem">
                  <path id="id3aCJoQ"
                    d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                    :fill="activeIcon == 'table1' ? '#009EA7' : '#8497AB'" p-id="4564"></path>
                </svg>
              </div>
              <div id="g66b8c"  @click="CheckClick('computer1')" :class=" activeIcon == 'computer1' ?'ub7903 u72482' :'ub7903'">
                <svg id="idNxDoHC" class="icon" t="1718690674036" viewBox="0 0 1214 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4367" width="2.57rem" height="2.57rem">
                  <path id="idT6sPU1"
                    d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                    :fill="activeIcon == 'computer1' ? '#009EA7' : '#8497AB'" p-id="4368"></path>
                  <path id="iddq3HRE"
                    d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                    :fill="activeIcon == 'computer1' ? '#009EA7' : '#8497AB'" p-id="4369"></path>
                </svg>
              </div>
            </div>
            <div class="perations-content-left margin-left20">
              <span :class="activeIEports == '1'
                ? 'perations-button perations-button-active'
                : 'perations-button'
                " @click="ceckIEports('1')"> Imports</span>
              <span @click="ceckIEports('2')" :class="activeIEports == '2'
                ? 'perations-button perations-button-active'
                : 'perations-button'
                ">Exports</span>
            </div>
          </div>
        </h2>
        <div class="flex_center_between_box">
          <div  v-if="activeIcon=='computer1'" class="Products_lesft" style="height: auto; ">
            <div ref="SupplyChainEchart" id="countriesSupplier" style="width: 100%; height: 100%;min-height: 40rem;"></div>
          </div>
          <div v-if="activeIcon=='table1'" class="table-biao" style="position: relative;">

            <el-table :data="ImporttableData" ref="table" empty-text="No Data" style="width: 100%"
              class="table_height_30 table_font_size_14 table_contentHeight_40">
              <el-table-column prop="impYear" label="Date" align="left" width="100"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.impYear }}</span>
                </template>
              </el-table-column>


              <el-table-column prop="hsCode" label="HS Code" align="left" show-overflow-tooltip>

                <template slot-scope="scope">
                  <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.hsCode }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="products" label="Products" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                    <span  style="color: #1290C9;cursor: pointer;">{{ scope.row.products }}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
              <el-table-column prop="partnerCompanyName" label="Major Buyers" v-if="activeIEports=='1'" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.partnerCompanyName}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
              <el-table-column prop="partnerCompanyName" label="Major Suppliers" v-if="activeIEports=='2'" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.partnerCompanyName}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
              <el-table-column prop="countryNameOfPartner" label="Destination Country" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span style="color: #1290C9;cursor: pointer;">{{ scope.row.countryNameOfPartner}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="Amount"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span  >{{ scope.row.amount}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
            </el-table>

          </div>
        </div>

      </div>
    </gui_card>
    <!-- <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" style="margin-top: 10px;">
      <div>
        <h2 class="operations-head flex_center_between_box">
          <span class="head-titel detail_title_20">Top 10 Imported Products</span>
          <el-select style="width: 14.28rem;"  v-model="Exportyear" placeholder="Please Select" @change="ExportYear">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </h2>
        <div class="flex_center_between_box">
          <div class="Products_lesft" style="height: auto; ">
            <div ref="SupplyChainEchart1" style="width: 98%; height: 100%"></div>
          </div>
          <div class="table-biao" style="position: relative;">

            <el-table :data="ExportTableData" ref="table" empty-text="No Data" style="width: 100%"
              class="table_height_30 table_font_size_14 table_contentHeight_40">
              <el-table-column prop="impYear" label="Date" align="left"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.impYear }}</span>
                </template>
              </el-table-column>


              <el-table-column prop="hsCode" label="HS Code" align="left" show-overflow-tooltip>

                <template slot-scope="scope">
                  <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.hsCode }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="products" label="Products" align="center"  show-overflow-tooltip>>
                <template slot-scope="scope">
                  <div class="flex_center">
                    <span class="table-content" style="color: #1290C9;cursor: pointer;">{{ scope.row.products }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="partnerCompanyName" label="Major Buyers" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                 
                    <span style="color: #1290C9;cursor: pointer;">{{ scope.row.partnerCompanyName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="countryNameOfPartner" label="Destination Country" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
             
                    <span style="color: #1290C9;cursor: pointer;">{{ scope.row.countryNameOfPartner}}</span>
           
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="Amount"  align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                    <span >{{ scope.row.amount}}</span>
                </template>
              </el-table-column>
            </el-table>

          </div>
        </div>

      </div>
    </gui_card> -->
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getNews,
  getProducts,
  getProductsYearMonth
} from "@/api/companyApi";
import rectcard from "@/components/rectCard/index.vue";
import content_text from "@/components/text_content/index.vue";
import gui_card from "@/components/gui_card/gui_card.vue";
import ReLogin from "@/components/ReLogin.vue";
//importEnd
import { mixins1 } from "@/mixins/index";
export default {
  name: "",

  props: {},
  mixins: [mixins1],
  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  computed: {},
  mounted() {

    this.getCountriesYearMonthList()
    // this.getNewsExportList()

  },
  data() {
    return {
      reLoginVis: false,
      Importyear: '',
      Yearoptions: [],
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      keyNum: 0,
      SearchHscode: "",
      SelectHscode: '',

      ImporttableData: [],
      ExportTableData: [],


      staticDataImport: [

      ],
      staticDataExport: [

      ],
      activeIEports: '1',
      activeIcon: 'computer1',
      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    ceckIEports(row) {
      this.activeIEports = row;
      this.getNewsImportList()
      this.getCountriesYearMonthList()
    },
    CheckClick(value) {
      this.getCountriesYearMonthList()
      this.activeIcon = value
      if (value == 'computer1') {
       this.$nextTick(()=>{
        this.getNewsImportList()

       })
      }
    },
    ImportYear() {
      this.getCountriesYearMonthList()

    },

    ImportsuppleEchart() {

      this.staticDataImport.map(item => {
        let countryImg = this.getCountryImgUrl(item[3]);
        item[3] = require("../../../../../static" + countryImg)

      })
      const startYear = this.Importyear; // 选择特定年份
      const source = this.staticDataImport.slice(1).filter(function (d) {
        return d[1] === startYear; // 过滤出特定年份的数据
      });
      source.sort((a, b) => b[2] - a[2]);
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      const option = {
        grid: {
          top: '5%',
          bottom: 30,
          left: 10,
          right: 20
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tar = params[0];
            return tar.axisValueLabel + ' : ' + tar.value;
          }
        },
        xAxis: {
          type: 'value',
          position: 'bottom', // 将 xAxis 放置在底部
          offset: 10, // 向下移动 10
          axisLabel: {
            show: true,
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
            formatter: function (value) {
              return value.length > 10 ? value.substring(0, 10) + '...' : value; // 超过10个字符则省略
            },
          },
          axisTick: { show: false }
        },

        yAxis:[{
          type: 'category',
          inverse: true,
          boundaryGap: false,
          axisLabel: {
            show: true,
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
            formatter: function (value) {

              return value.length > 10 ? value.substring(0, 10) + '...' : value; // 超过10个字符则省略
            },
          },
          data: source.map(item => item[0]), // 添加国家名称作为 x 轴数据
          // position: 'bottom',
          // offset: -10,
          axisLine: { show: false },
          axisTick: { show: false }
        },
        {
          type: 'category',
          inverse: true,
          boundaryGap: false,
          axisLabel: {
            show: true,
            fontSize: 14,
            textStyle: {
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' // 字体类型
        },

            formatter: function (value) {

              return value.length > 10 ? value.substring(0, 64) + '...' : value; // 超过10个字符则省略
            },
          },
          data: source.map(item => item[0]), // 添加国家名称作为 x 轴数据
          margin: 20, // 设置标签与坐标轴的间距
          offset: -460,
          zlevel:4,
          nameLocation:'start',
          axisLine: { show: false },
          axisTick: { show: false }
        },
  ],  
        series: [
          {
            type: 'bar',
            barWidth: '25',
            showBackground: true,
            backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      },
            itemStyle: { color: '#009EA7' },
            emphasis: {
              itemStyle: { color: '#009EA7' }
            },
            label: {
        show: false,
              color: '#fff',
              z: 3,
         
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['name'])
              }
      },
            data: source.map(item => item[2]) // 只保留值
          }
        ],

      
      };

      this.echartsBox.setOption(option);
      this.$nextTick(() => {
        this.echartsBox.resize();


      })
    },
  
    getCountriesYearMonthList() {
      getProductsYearMonth({
        aaaId: this.$route.query.id3a,
        type: this.activeIEports
      }).then(res => {
        this.Yearoptions = res.data
        this.Importyear=res.data[0]
    this.getNewsImportList()
   
      })
    },
    getNewsImportList() {
      getProducts({
        aaaId: this.$route.query.id3a,
        year: this.Importyear,
        type: this.activeIEports
      }).then(res => {
        if (res.code == 200) {
          this.staticDataImport=[]
          this.ImporttableData = res.data
          res.data.map(item=>{
            this.staticDataImport.push([
              item.products,
              item.impYear,
              item.transactions,
              item.countryOfPartner
            ])
          })
          let myChart = this.$echarts.init(document.getElementById('countriesSupplier'))
          myChart.dispose();
          // this.staticDataImport = res.data
          this.staticDataImport.unshift(['Country', 'Year', 'Life Expectancy', 'CountryCode']),
            this.ImportsuppleEchart()
        }
      })
      // getfilterConditions()
    },
   

    ShowAll() {


    },
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
    font-size: 14px;

  }
}


.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: 'DMSans Bold';
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497AB;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.Products_lesft {
  width: 100%;
}

.table-biao {
  width: 100%;
}
/deep/ .el-table td.el-table__cell {
    border-right: none;
    height: 3.71rem;
}
</style>
